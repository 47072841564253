<template>
  <div id="allReports">
    <a-page-header
      title="报告管理"
      style="padding-left: 0; margin-bottom: 10px; border-bottom: 1px solid rgb(235, 237, 240)"
    />

    <div class="header">
      <a-form layout="inline">
        <a-form-item label="报告名称: ">
          <a-input style="width: 180px" v-model.trim="form.reportName" placeholder="请输入" />
        </a-form-item>
        <!-- <a-form-item label="平台: ">
          <a-select
            :getPopupContainer="
              (triggerNode) => {
                return triggerNode.parentNode || document.body;
              }
            "
            placeholder="请选择"
            option-filter-prop="children"
            style="width: 180px"
            :maxTagCount="1"
            :maxTagTextLength="4"
            :filter-option="filterOption"
            mode="multiple"
            v-model.trim="form.reportPlatform"
          >
            <a-select-option v-for="(item, index) in reports" :value="item.id" :key="index">
              {{ item.commonsValueName }}
            </a-select-option>
          </a-select>
        </a-form-item> -->
        <a-form-item label="状态: ">
          <a-select
            :getPopupContainer="
              (triggerNode) => {
                return triggerNode.parentNode || document.body;
              }
            "
            style="width: 180px"
            placeholder="请选择"
            mode="default"
            v-model.trim="form.reportStatus"
          >
            <a-select-option v-for="(item, index) in reportStatus" :value="item.id" :key="index">
              {{ item.title }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="类型: ">
          <a-select
            :getPopupContainer="
              (triggerNode) => {
                return triggerNode.parentNode || document.body;
              }
            "
            style="width: 180px"
            placeholder="请选择"
            mode="default"
            @change="reportTypeChange()"
            v-model.trim="form.reportTypeParam"
          >
            <a-select-option value="">全部</a-select-option>
            <a-select-option v-for="(item, index) in reportTypeList" :value="item.commonsValue" :key="index">
              {{ item.commonsValueName }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="分类">
          <a-select
            :getPopupContainer="
              (triggerNode) => {
                return triggerNode.parentNode || document.body;
              }
            "
            placeholder="请选择"
            option-filter-prop="children"
            style="width: 180px"
            :maxTagCount="1"
            :maxTagTextLength="4"
            :filter-option="filterOption"
            mode="multiple"
            v-model.trim="form.reportClassify"
          >
            <a-select-option v-for="(v, i) in classTypeList" :key="i" :value="v.id"
              >{{ v.classifyName }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="标签：">
          <a-select
            :getPopupContainer="
              (triggerNode) => {
                return triggerNode.parentNode || document.body;
              }
            "
            placeholder="请选择"
            option-filter-prop="children"
            style="width: 180px"
            :maxTagCount="1"
            :maxTagTextLength="4"
            :filter-option="filterOption"
            mode="multiple"
            v-model.trim="form.reportLable"
          >
            <a-select-option v-for="(v, i) in labelSelect" :key="i" :value="v.labelId"
              >{{ v.labelName }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <span class="btnClass">
          <a-button type="primary" @click="search" style="margin-top: 3px">
            <a-icon type="search" />
            查询
          </a-button>
          <a-button style="margin-left: 15px" @click="resetSearchForm"> 重置 </a-button>
        </span>
      </a-form>
    </div>
    <div class="middle">
      <!-- <a-button
        type="primary"
        @click="showModal"
        v-if="$checkButtonPermissions($buttonPermissionsConfig.reportManagement.reportManage.add)"
      > -->
      <a-button type="primary" @click="showModal"> <a-icon type="plus" />添加 </a-button>
      <a-table
        :rowKey="
          (record, index) => {
            return index;
          }
        "
        style="margin-top: 15px"
        :pagination="false"
        :columns="columns"
        :data-source="list"
        :scroll="{ x: 1500 }"
        bordered
      >
        <template slot="classifyName" slot-scope="text, record">
          <a-tooltip placement="top">
            <template slot="title">
              <span>{{ record.classifyName }}</span>
            </template>
            <div class="overText">
              {{ record.classifyName }}
            </div>
          </a-tooltip>
        </template>
        <template slot="reportName" slot-scope="text, record">
          <a-tooltip placement="top">
            <template slot="title">
              <span>{{ record.reportName }}</span>
            </template>
            <div class="overText">
              {{ record.reportName }}
            </div>
          </a-tooltip>
        </template>
        <template slot="sort" slot-scope="text, record">
          <div>
            <a-input
              v-model="record.sequence"
              :maxLength="9"
              @blur="onSearch"
              @focus="focusValue(record.sequence, record.id)"
              @pressEnter="onSearch"
              style="width: 150px; margin: -5px 0"
              @input="(e) => handleChange(e.target.value, record.id)"
            />
          </div>
        </template>
        <template slot="action" slot-scope="text, record">
          <a
            href="javascript:;"
            style="color: #1890ff"
            @click="modify(record.id)"
            v-if="$checkButtonPermissions($buttonPermissionsConfig.reportManagement.reportManage.edit)"
            >编辑</a
          >&nbsp;&nbsp;
          <a
            href="javascript:;"
            style="color: #1890ff"
            @click="SEE(record)"
            v-if="$checkButtonPermissions($buttonPermissionsConfig.reportManagement.reportManage.look) && show"
            >预览</a
          >&nbsp;&nbsp;
          <a
            href="javascript:;"
            style="color: #1890ff"
            @click="removeList(record.id)"
            v-if="$checkButtonPermissions($buttonPermissionsConfig.reportManagement.reportManage.delete)"
            >删除</a
          >
        </template>
      </a-table>
    </div>
    <div class="fotter">
      <a-pagination
        show-size-changer
        :show-total="(total) => `共 ${total} 条`"
        show-quick-jumper
        :pageSizeOptions="['20', '50', '100']"
        :defaultPageSize="pageSize"
        :defaultCurrent="pageNum"
        :current="pageNum"
        :showSizeChanger="true"
        :pageSize="pageSize"
        :total="total"
        @change="onChangePage"
        @showSizeChange="onShowSizeChange"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      reportStatus: [
        { title: "全部", id: "" },
        { title: "启用", id: "0" },
        { title: "禁用", id: "1" },
      ],
      pageSize: 20,
      pageNum: 1,
      show: true,
      changeId: "", //失去焦点 =>id
      sequence: null, //失去焦点 =>input 文案
      inputChangeId: {}, //动态model
      form: {
        reportPlatform: [], //平台
        reportStatus: "", //状态
        reportTypeParam: "", //类型
        reportName: "", //报告名称
        reportLable: [], //报告标签
        reportClassify: [], //报告分类
      },
      columns: [
        {
          title: "ID",
          dataIndex: "reportNumber",
          width: 80,
        },
        {
          title: "类型",
          dataIndex: "reportType",
        },
        {
          title: "分类",
          dataIndex: "classifyName",
          scopedSlots: { customRender: "classifyName" },
        },
        {
          title: "报告名称",
          dataIndex: "reportName",
          scopedSlots: { customRender: "reportName" },
        },
        {
          title: "手动排序",
          scopedSlots: { customRender: "sort" },
          width: 200,
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreate",
          width: 200,
          customRender: (text) => {
            return moment(text).format("YYYY-MM-DD HH:mm:ss");
          },
        },
        {
          title: "更新时间",
          width: 200,
          dataIndex: "gmtModified",
          customRender: (text) => {
            return moment(text).format("YYYY-MM-DD HH:mm:ss");
          },
        },
        {
          title: "状态",
          dataIndex: "reportStatus",
          width: 100,
        },
      ],
      // 操作集合
      actions: [
        this.$buttonPermissionsConfig.reportManagement.reportManage.edit,
        this.$buttonPermissionsConfig.reportManagement.reportManage.look,
        this.$buttonPermissionsConfig.reportManagement.reportManage.delete,
      ],
    };
  },

  created() {
    this.$checkTableActionIsShow(this.actions) && this.columns.push(this.$backTableAction({ width: "150px" }));
    // this.platformCollection({ commonsKey: "REPORT_PLATFORM" }); //平台下拉数据
    this.platformCollection({ commonsKey: "REPORT_TYPE" }); //报告类型下拉数据
    this.getClassSelectList({ type: "all" }); //获取分类下拉值
    this.getLabelSelect(); //获取标签下拉值
  },
  mounted() {
    this.getData();
  },
  computed: {
    ...mapState({
      list: (state) => state.allReports.list,
      // reports: (state) => state.allReports.reports,
      total: (state) => state.allReports.total,
      reportTypeList: (state) => state.allReports.reportTypeList,
      labelSelect: (state) => state.dashboard.labelSelect,
      classTypeList: (state) => state.dashboard.classTypeList,
    }),
  },
  methods: {
    ...mapActions({
      platformCollection: "allReports/platformCollection",
      getAllclassify: "allReports/getAllclassify",
      reportCollection: "allReports/reportCollection",
      uninstallReport: "allReports/uninstallReport",
      sortTable: "allReports/sortTable",
      getSelectList: "dashboard/getSelectList",
      getLabelSelect: "dashboard/getLabelSelect",
      getClassSelectList: "dashboard/getClassSelectList",
    }),
    handleChange(value, id) {
      this.sequence = value;
      this.changeId = id;
    },
    //类型change事件
    reportTypeChange() {
      this.form.reportClassify = [];
      this.classTypeList = [];
      this.$nextTick(() => {
        this.getClassSelectList({ type: this.form.reportTypeParam });
      });
    },

    //模糊查询
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    //表格排序
    focusValue(value, id) {
      this.sequence = value && value != 0 ? value : 0;
      this.changeId = id;
    },
    onSearch() {
      if (this.sequence < 0) {
        this.warning("请输入正数");
        return false;
      }
      if (this.sequence.length > 0 && this.sequence == 0) {
        this.warning("数字最小为1");
        return false;
      }
      if (this.sequence.length < 1) {
        this.sequence = 0;
      }
      if (isNaN(this.sequence)) {
        this.warning("请输入数字");
        return false;
      } else {
        this.sortTable({
          data: {
            id: this.changeId,
            //四舍五入取整
            sequence: this.sequence ? Math.round(this.sequence) : 0,
          },
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          form: this.form,
          cb: () => {
            this.success("排序成功");
            this.getData();
          },
          cbError: () => {
            this.error("排序失败");
          },
        });
      }
    },

    //编辑
    modify(id) {
      this.$router.push({
        path: "/reportManagement/addModify",
        query: {
          Disable: false,
          isText: 1,
          id,
        },
      });
    },

    //添加
    showModal() {
      this.$router.push({
        path: "/reportManagement/addModify",
        query: {
          Disable: false,
          isText: 0,
        },
      });
    },
    //预览
    SEE(src) {
      if (src.reportType == "分析报告") {
        window.open(`${src.reportDocumentUrl}#toolbar=0`);
      } else {
        window.open(src.reportUrl);
      }
      this.show = false;
      setTimeout(() => {
        this.show = true;
      }, 1);
    },

    success(message) {
      this.$message.success(message);
    },
    error(message) {
      this.$message.error(message);
    },
    warning(message) {
      this.$message.warning(message);
    },
    //删除
    removeList(id) {
      this.$confirm({
        title: "确定要删除吗?",
        content: "删除后不可恢复",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk: () => {
          this.uninstallReport({
            id,
            pageNum: 1,
            pageSize: this.pageSize,
            cb: () => {
              this.success("删除成功");
              this.getData();
            },
            cbError: (res) => {
              this.warning(res);
            },
          });
        },
        onCancel: () => {
          // this.warning("已取消");
          return false;
        },
      });
    },
    getData() {
      let obj = {
        reportPlatform: this.form.reportPlatform,
        reportTypeParam: this.form.reportTypeParam,
        reportLable: this.form.reportLable,
        reportClassify: this.form.reportClassify,
        reportStatus: this.form.reportStatus,
        reportName: this.form.reportName,
      };
      this.reportCollection({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        ...obj,
      });
    },
    //查询
    search() {
      this.pageNum = 1;
      this.getData();
    },
    //重置
    resetSearchForm() {
      this.form = this.$options.data.call(this).form;
      this.pageSize = 20;
      this.pageNum = 1;
      this.getData();
      this.getClassSelectList({ type: "all" }); //获取分类下拉值
    },

    //分页
    onShowSizeChange(pageNum, pageSize) {
      this.pageNum = pageNum;
      this.pageSize = pageSize;
      this.getData();
    },
    onChangePage(pageNum, pageSize) {
      this.pageNum = pageNum;
      this.pageSize = pageSize;
      this.getData();
    },
  },
};
</script>

<style scoped>
.overText {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.header {
  padding: 0 10px;
  margin-top: 15px;
}

.header button {
  margin-left: 20px;
}

.middle {
  margin-top: 15px;
}

.fotter {
  float: right;
  padding: 20px 0;
}

.btnClass {
  margin-left: 10px;
}
</style>
